body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  }

/* .carousel .slide  {
  @media screen and (max-width: 1025px) {
    
  }
} */

.carousel li.slide img {
  width: auto;
  height: 450px;
}

.carousel li.thumb img {
  /* display: none;
  border: hidden; */
  height: 100px;
}